<template>
    <div>
        <v-app-bar
            fixed
            color="primary">
            <v-img
                class="mx-1"
                src="@/assets/logo.png"
                max-width="30"
                max-height="30"
                contain>
            </v-img> 
            <v-toolbar-title class="white--text mr-1 subtitle-2">
                עם ישראל חי
            </v-toolbar-title>

           
            <v-spacer/>

            <v-btn
                v-if="installApp"
                class="primary--text"
                rounded
                small
                color="white"
                dark
                @click="install">התקנה
                <v-icon 
                    right
                    color="primary">mdi-download</v-icon>
            </v-btn>
            <v-spacer/>

            <v-btn
                to="/favorites"
                class="primary--text ml-n2"
                rounded
                small
                color="white">שמורים
                <v-icon color="fav"
                    right 
                    medium>mdi-star</v-icon>
            </v-btn>
            
        </v-app-bar>
    </div>

</template>


<script>
export default {
    data() {
        return {
            installApp: null,
            registration: null
        }
    },
    methods: {
        async install() {
            this.installApp.prompt();
        },
        updateAvailable(event) {
            this.registration = event.detail
            this.refreshApp()
        },
        refreshApp() {
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    },
    created() {
        //Catch install event before prompt and save on 'installApp' variable
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.installApp = e;
        });
        //Called when app installed
        window.addEventListener("appinstalled", () => {
            this.installApp = null;
        });
        //Event listener for update - run once and remove listener
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
    }
}
</script>